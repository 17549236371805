import CampaignVoiceInteractiveStats from '@/views/Pages/Share/Stats/Voice/Campaign/CampaignVoiceInteractiveStats.vue'
import CampaignVoiceBasicStats from '@/views/Pages/Share/Stats/Voice/Campaign/CampaignVoiceBasicStats.vue'

export default {
    props: [],
    name: 'VoiceCampaignStats',
    components: {
      CampaignVoiceInteractiveStats,
      CampaignVoiceBasicStats,
    },
    data: function () {
      return {

      }
    },
    created () {
      this.campaignId = +this.$route.params.id
      this.campaignType = this.$route.params.type
    },
}
